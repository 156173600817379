import(/* webpackMode: "eager" */ "/app/apps/web/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/components/loading/nprogress-loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AbilityProvider"] */ "/app/apps/web/contexts/abilities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AgentProvider"] */ "/app/apps/web/contexts/agent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/contexts/i18n-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/app/apps/web/contexts/next-auth-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/i18n/index.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Noto_Sans_Thai\",\"arguments\":[{\"subsets\":[\"thai\"],\"display\":\"swap\",\"variable\":\"--font-noto-sans-thai\"}],\"variableName\":\"notoSansThai\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Sarabun\",\"arguments\":[{\"weight\":[\"300\",\"400\"],\"subsets\":[\"thai\"],\"display\":\"swap\",\"variable\":\"--font-sarabun\"}],\"variableName\":\"sarabun\"}");
